<div class="container pt-4">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6">
      <div class="card rounded-xl">
        <div class="card-body">
          <label for="">Give {{ isWoofing ? "vBTC" : "gSAT" }}</label>
          <a
            class="text-secondary small ml-3"
            href="https://explorer-mainnet.maticvigil.com/address/{{
              isWoofing ? constants.VBTC : constants.GSAT
            }}"
            target="_blank"
            >Matic</a
          >
          <div class="input-group input-group-lg mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-lg">{{
                isWoofing ? "vBTC" : "gSAT"
              }}</span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="0.00"
              aria-label=""
              aria-describedby="basic-addon2"
              [ngModel]="inputAmount.toString() !== 'NaN' ? inputAmount.toString() : 0"
              (ngModelChange)="updateInputAmount($event)"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="maxPower()"
              >
                Max
              </button>
            </div>
          </div>
          <div class="row justify-content-center center-swap">
            <div class="col-auto">
              <button
                class="btn btn-lg btn-rounded-circle btn-white r-btn"
                (click)="isWoofing = !isWoofing"
              >
                <img
                  src="./assets/img/swap_vert_white_24dp.svg"
                  class=""
                  alt="Swap vertical icon"
                />
              </button>
            </div>
          </div>
          <label for="">Get {{ isWoofing ? "gSAT" : "vBTC" }}</label
          ><a
            class="text-secondary small ml-3"
            href="https://explorer-mainnet.maticvigil.com/address/{{
              isWoofing ? constants.GSAT : constants.VBTC
            }}"
            target="_blank"
            >Matic</a
          >
          <div class="input-group input-group-lg mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-lg">{{
                isWoofing ? "gSAT" : "vBTC"
              }}</span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="0.00"
              aria-label=""
              aria-describedby="basic-addon2"
              disabled
              aria-disabled="true"
              value="{{
                isWoofing 
                  ? mphToEIN(inputAmount).toString() !== 'NaN' ? mphToEIN(inputAmount).toString() : 0
                  : einToMPH(inputAmount).toString() !== 'NaN' ? einToMPH(inputAmount).toString() : 0
              }}"
            />
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-6" *ngIf="isWoofing">
              <a
                class="btn btn-lg btn-block btn-outline-white glowing"
                [ngClass]="{ disabled: canWoof() }"
                (click)="approve()"
                >Approve</a
              >
            </div>
            <div class="col-6" *ngIf="isWoofing">
              <a
                class="btn btn-lg btn-block btn-bg-gradient border-0 glowing"
                [ngClass]="{ disabled: !canWoof() }"
                (click)="woof()"
                >Purr</a
              >
            </div>
            <div class="col-12" *ngIf="!isWoofing">
              <a
                class="btn btn-lg btn-block btn-bg-gradient border-0 glowing"
                (click)="unwoof()"
                >Snarl</a
              >
            </div>
          </div>
          <hr>
          <div class="row justify-content-center">
            <a
              class="link-underlined"
              href="https://app.sushi.com/swap?inputCurrency=0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270&outputCurrency=0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd"
              target="_blank"
              >Trade gSAT on 🍣</a
            >
          </div>
        </div>
      </div>
      <!-- <div class="row justify-content-center">
        <div class="col-auto">
          <p class="text-center text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in cidi dunt ut labore et dolore magna aliqua. Turpis cursus in hac habitasse platea dic
          </p>
          <p class="text-center text-secondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in cidi dunt ut labore et dolore magna aliqua. Turpis cursus in hac habitasse platea dic
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>
