<div class="container-fluid justify-content-between px-0 px-lg-6">
  <div>
    <a class="text-white text-left mb-3 d-block mt-3" [routerLink]="">
      <img class="logo" src="./assets/img/grumpysat-logo.svg" class="mr-3" alt="GrumpySat" height="32px">
    </a>
  </div>
  <div class="d-inline-flex">
    <a class="btn btn-lg btn-light d-done d-md-block" target="_blank" href="https://explorer-mainnet.maticvigil.com/address/0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd">
      {{mphBalance}} gSat
    </a>
    <a class="btn btn-lg btn-light d-done d-md-block ml-3" target="_blank" href="https://explorer-mainnet.maticvigil.com/address/0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9">
      {{einBalance}} vBTC
    </a>
    <!-- Connect/watch button -->
    <div class="btn-group ml-3">
      <button type="button" class="btn btn-light btn-lg" (click)="connectWallet()">
        <div *ngIf="wallet.connected">
          {{wallet.userAddress.substring(0, 14)}}
        </div>
        <div *ngIf="!wallet.connected">
          Connect Wallet
        </div>
      </button>
    </div>

  </div>
</div>
